<template>
  <div class="about" >
    <div class="d-flex flex-column min-vh-100">
    <Menubar msg="test" @query="query" @getwinsize="getwinsize" v-if="type=='N'" />  
      <div class="lineHeader" v-if="type=='Y'">
        <a-button type="link" @click="toNext()"  icon="left" class="fontstyle">
          回首頁
        </a-button>      
      </div>
      <div style="padding:1.7rem 0 0 0;" v-if="type=='Y'" />        
      <ul>
        <li>
          <div class="mt-3" >
            <div v-if="list!==null" style="background:#ECECEC; padding:5px">

              <b-card no-body class="overflow-hidden" style="max-width: 800px;">
                  <b-row no-gutters>
                    <b-col md="6">
                      <b-card-img src="https://picsum.photos/400/400/?image=20" alt="Image" class="rounded-0"></b-card-img>
                    </b-col>
                    <b-col md="6">
                      <b-card-body class="text-left" :title="'活動名稱 : '+list.actname">
                        <b-card-text class="text-left" >
                          參加人員 : {{list.username}}
                          交易狀態 : {{list.rtnCode=='1'? '付款成功':'付款失敗'}}<br/>
                          交易金額 : {{list.tradeAmt}}<br/>
                          通路費 : {{list.paymentTypeChargeFee}}<br/>
                          付款時間 : {{fdateformat(list.paymentDate)}}<br/>
                          訂單品項 : {{list.orderitem}}<br/>
                          訂單品項說明 : {{list.orderdesc}}<br/>
                        </b-card-text>
                      </b-card-body>
                    </b-col>
                  </b-row>
             </b-card>              
            </div>            
          </div>      
        </li>
      </ul>            
    <div class="wrapper flex-grow-1"></div>      
    <Footbar msg="test" v-if="type=='N'" />
    <div class="applyfooter" v-if="windowsize.width<=991">
    </div>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex';
import { joinService } from '../_services'
import * as moment from "moment/moment";
import Menubar from '@/components/Menubar.vue'
import Footbar from '@/components/Footbar.vue'
import router from '../router'

const queryData = params => {
  return joinService.myorder(params.id) 
};

export default {
  name: 'Myorder',
  components: {
    Menubar,
    Footbar,
  }, 
  props: {
    msg: String
  },
  data() {
    return {
      windowsize: {
            width: 0,
            height: 0
        },       
      classtheme:null,
      iconLoading:false,
      list:null, 
      tradeno:'',  
      type:'N', 
      uId:'',
    };
  }, 
  computed: {
    ...mapState("account", ["status", "user"]),
  },   
  mounted(){
    /*this.uId = this.user.data.user.id
    if(this.uId!=='' || this.uId!==undefined){
        router.replace('/').catch(err=>{err})
    } */   
    this.type = this.$route.params.type
    this.uId = this.user.data.user.id
    this.tradeno = this.$route.params.id
    this.fetch();
  },
  methods: {
    query(rs){
      this.classtheme = rs
    },
    getwinsize(val){
      this.windowsize.width = val.width
      this.windowsize.height = val.height
    },           
    fetch(
      params = {
          id:this.tradeno,              
      }) {
      queryData({
        ...params,
      }).then(({ data }) => {
            this.list=data
            if(data==null){
              router.replace('/').catch(err=>{err})
            }
      });
    },

    fdateformat(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    },
    toNext(){
      router.replace({ name: 'Myline'})
    }    
  },      
}
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.sidenav a:hover {
  color: #064579;
}
.card-title{
  font-size: 15px;
}
.card-text{
  font-size: 14px;
}
.applyfooter {
   position: fixed;
   left: 0;
   bottom: 0;
   width: 100%;
   height: 0px;
   background-color: #f0f8f8;
   color: #f0f8f8;
   text-align: center;
   
}
.contantdiv{
  margin: 5px;
  overflow-x: hidden;
}

.lineHeader {
   position: fixed;
   left: 0;
   top: 0;
   z-index: 7;
   opacity:1;
   width: 100%;
   height: 2.3rem;
   padding: 0.2rem;
   background-color: #3b1b75;
   color: #f0f8f8;
   text-align: center;
   
}
.fontstyle{
   font-size:0.8rem;
   color:#faf8f8;
}


</style>

